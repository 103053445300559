@mixin transition($args...) {
  @each $arg in $args {
    -webkit-transition: $arg;
    -moz-transition: $arg;
    -ms-transition: $arg;
    -o-transition: $arg;
    transition: $arg;
  }
}
@mixin gray-scrollbar {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 8px; /*sets the thickness of vertical scroll  */
    height: 8px; /*sets the thickness of horizontal scroll */
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}
