.google-signin-btn {
  border-color: $black !important;
  color: map-get($gray, '300') !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 2rem !important;
  letter-spacing: -0.023rem !important;
  border-radius: 0.313rem !important;
  &:hover {
    background: map-get($gray, '200') !important;
  }
}
