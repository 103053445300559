// background color classes
.bg-gray-800 {
  background: map-get($gray, '800') !important;
}
.bg-white {
  background: $white !important;
}
// text color classes
.text-saffron-700 {
  color: map-get($saffron, '700') !important;
}
