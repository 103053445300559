.sidebar {
  @include gray-scrollbar;
  .nav-item:hover,
  .nav-element:hover,
  .nav-element.active,
  .nav-item.active {
    background: map-get($gray, '800');
    border-radius: 0.5rem;
  }
}
.nav {
  margin-right: 1rem;
}
.nav-menu-item:hover {
  background: map-get($gray, '900') !important;
}
.navlink {
  display: flex;
  width: 100%;
  padding: 0.5rem;
  margin: 1rem 0;
  font-size: 0.75rem !important;
  letter-spacing: 0.05rem !important;
  &:hover,
  &.active {
    background: map-get($gray, '800');
    border-radius: 0.5rem;
  }
}
.top-menu {
  background-color: rgba(0, 0, 0, 0.06);
  border-bottom-width: 1px;
  border-color: rgba(0, 0, 0, 0.15);
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  opacity: 0.25;
  width: 100vw;
  height: 100vh;
  background-color: $black;
}
.timezone-section {
  select option {
    color: $black !important;
  }
}
