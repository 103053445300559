// roate
.rotate-0 {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  @include transition(transform 0.35s linear);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  @include transition(transform 0.35s linear);
}
// opacity
.transition-opacity {
  transition-duration: 0.15s;
  transition-property: opacity;
}
// custom class
.s1 {
  font-weight: 500;
  font-size: 2.75rem; // 44px
  line-height: 3.5rem; // 56px
  letter-spacing: -0.11rem; // -1.76px
}

.s2 {
  font-weight: 500;
  font-size: 2.375rem; // 38px
  line-height: 3.25rem; // 52px
  letter-spacing: -0.095rem; // -1.52px
}

.s3 {
  font-weight: 600;
  font-size: 1.5rem; // 24px
  line-height: 2.375rem; //  38px
  letter-spacing: -0.03rem; // -0.48px
}

.s4 {
  font-weight: 500;
  font-size: 1.875rem; // 30px
  line-height: 2.5rem; // 40px
  letter-spacing: -0.075rem; // -1.2px
}

.s5 {
  font-weight: 500;
  font-size: 1.125rem; // 18px
  line-height: 2rem; // 32px
  letter-spacing: -0.023rem; // -0.36px
}

.s6 {
  font-weight: 600;
  font-size: 1rem; // 16px
  line-height: 1.625rem; // 26px
  letter-spacing: -0.02rem; // -0.32px
}

.s6a {
  font-weight: 600;
  font-size: 1rem; // 16px
  line-height: 1.75rem; // 28px
  letter-spacing: -0.02rem; // -0.32px
}

.s7 {
  font-weight: 600;
  font-size: 0.938rem; // 15px
  line-height: 1.625rem; // 26px
  letter-spacing: -0.019rem; // -0.32px
}
.s8 {
  font-weight: 600;
  font-size: 0.813rem; // 13px
  line-height: 1.25rem; // 20px
  letter-spacing: 0;
}

.s9 {
  font-weight: 600;
  font-size: 0.75rem; // 12px
  letter-spacing: 0.15rem; // 2.4px
  line-height: normal;
  text-transform: uppercase;
}
.s10a {
  font-weight: 600;
  font-size: 0.625rem; // 10px
  line-height: normal;
  letter-spacing: 0.125rem; // 2px
  text-transform: uppercase;
}
.s10b {
  font-weight: 700;
  font-size: 0.625rem; // 10px
  letter-spacing: 0.125rem; // 2px
  line-height: normal;
  text-transform: uppercase;
}
.s10c {
  @extend .s10a;
  line-height: 1rem; // 16px
}
.s11 {
  font-weight: 600;
  font-size: 0.75rem; // 12px
  line-height: normal;
  letter-spacing: 0rem;
}
.s12 {
  font-size: 1.875rem; // 30px
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem; // 40px
  letter-spacing: -0.075rem; // -1.2px
}
.s-captions {
  font-size: 0.625rem; // 10px
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.s-small-description {
  font-size: 0.75rem; //12px
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; //20px
}
// text
.custom-ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
}
// icon
.icon-md {
  stroke-width: 1.5;
  height: 18px;
  width: 18px;
}
//border
.border-gray-900 {
  border-color: map-get($gray, '900') !important;
}
//z-index
.z100 {
  z-index: 100 !important;
}
.z1080 {
  z-index: 1080;
}
// position properties related classes
.top-0 {
  top: 0;
}
.fixed-top {
  position: sticky;
  right: 0;
  left: 0;
  z-index: 1030;
}
// flex
.align-self-center {
  align-self: center;
}
// transition
.fade {
  transition: opacity 0.15s linear;
}
// cursor
.cursor-pointer {
  cursor: pointer;
}
.pe-none {
  pointer-events: none !important;
}
// section field first option will be disabled
.chakra-select {
  & option:first-child {
    pointer-events: none !important;
  }
}
// display
.d-none {
  display: none !important;
}
// menu button
.menu-btn {
  background: $white !important;
  &:hover,
  &:active {
    background: $white !important;
  }
}
