.pagination-container {
  .pagination-item {
    padding: 0 0.75rem;
    height: 2rem;
    text-align: center;
    margin: auto 0.25rem;
    color: map-get($custom-black, '300');
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.019rem;
    border-radius: 1rem;
    font-size: 0.813rem;
    min-width: 2rem;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: map-get($custom-black, '100');
      cursor: pointer;
    }

    &.selected {
      background-color: map-get($custom-black, '200');
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        border-right: 0.12rem solid map-get($custom-black, '300');
        border-top: 0.12rem solid map-get($custom-black, '300');
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12rem solid map-get($custom-black, '400');
        border-top: 0.12rem solid map-get($custom-black, '400');
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
