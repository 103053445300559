.inputfield {
  border-radius: 5px !important;
  border: 1px solid $black !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.02rem !important;
  background: $white !important;
  &:disabled {
    cursor: not-allowed !important;
    background: map-get($gray, '100') !important;
  }
  &:focus,
  &:focus-within,
  &:hover {
    border: 1px solid $black !important;
    box-shadow: none !important;
  }
  &[aria-invalid='true'] {
    border: 1px solid $invalid-color !important;
    box-shadow: none !important;
  }
  &:-webkit-autofill {
    box-shadow: none !important;
    border: 1px solid $black !important;
    background: $white !important;
  }
}
input:-internal-autofill-selected {
  box-shadow: none !important;
  border: 1px solid $black !important;
  background: $white !important;
}
