$invalid-color: rgba(178, 34, 34, 1);
$white: #fff;
$black: #000;

$gray: (
  '100': #edf2f7,
  '200': #f2f5f5,
  '300': #3c4043,
  '400': #858585,
  '800': #202123,
  '900': rgba(52, 53, 65, 1),
);

$saffron: (
  '700': #bf9000,
);

$custom-black: (
  '100': rgba(0, 0, 0, 0.04),
  '200': rgba(0, 0, 0, 0.08),
  '300': rgba(0, 0, 0, 0.87),
  '400': rgba(0, 0, 0, 0.43),
);

$custom-colors: (
  'gray': $gray,
  'saffron': $saffron,
  'custom-black': $custom-black,
);
