.custom-table {
  th {
    border-bottom: 1px solid $black !important;
    color: $black !important;
  }
  tbody tr:hover {
    background-color: map-get($gray, '200') !important;
  }
}
.table-header-fixed {
  overflow: unset !important;
  & table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
  }
  & thead {
    position: sticky;
    top: 0;
    left: 0;
    background: $white;
  }
}
